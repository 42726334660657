/*******custom-component css*******/

.inner-page-main {
    height: calc(100svh - 4.5em);
    overflow-y: auto;
}










.min-card-box {
    display: none;
    flex-direction: row;
    width: 100%;
    height: 6em;
    align-items: center;
    justify-content: space-between;
    transform: translateY(-2em);
}


.min-car-card-box .car-card-box { 
    display: none ;
 }

 .min-car-card-box .min-card-box {
    display: flex; 
 }



.min-car-card-box  .min-car-card .car {
    max-width: 90em;
}

.min-car-card-box .car {
    max-width: 90em;
}



img.car-image-min {
    max-height: 6em;
}

.min-car-card-box .car-card.active {
    width: 100% !important;
}

.min-car-card-box .car-carousel-inner {
    width: 100% !important;
}




@media screen and (max-device-width: 580px), (max-width: 768px){


    .min-card-box {
        width: 100% !important;
        padding: 0 1.5em;
    }
    .min-car-card-box .car-cards-section .user-car .car-carousel {
        padding: 0 0; 
    }  


}

@media only screen and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {

    
    
}

.min-car-card-box .car-cards-section .user-car .car-card.active .car-image {
    position: absolute;
    height: calc(100% - 2em);
    width: auto;
    aspect-ratio: 16/9;
    right: 0;
    top: -1em;
    bottom: 2em;
    left: auto;
    margin: auto;
    transition: all 2s linear;
}
.legal-page-container {
    height: calc(100svh - 4.5em);
    background: #f2f2f2; 
    padding: 1.5em 1.5em;
    overflow-y: auto;
    overflow-x: hidden;
}





 
